<template>
  <div class="p-1">
    <div-table
      :config="configList"
      :idWharhouse="idWharhouse"
      :currentUser="currentUser"
      :data="content"
      :tabId="tabId"
      :selectProduct="selectProduct"
      :producWarhouse="producWarhouse"
      @reset="reset"
      @refresh="refresh"
      @filtrer="filtrer"
      @searchSellers="searchSellers"
      @search="search"
      :loading="loading"
      :paginate="paginate"
      @paginatation="paginatation"
      @getRowDetail="getRowDetail"
      @emtyTabId="emtyTabId"
    ></div-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import config from "./config";
import lodash from "lodash";

export default {
  components: {},
  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object },
  },
  data() {
    return {
      configList: config.list,
      selected: [],
      tabId: [],
      loading: false,
      content: {},
      products: [],
      categories: [],
      sellers: [],
      statusOrder: [],
      // currentUser: {},
      selectProduct: {},
      producWarhouse: {},
      limit: 10,
      filters: {},
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
      },
      // idWharhouse:null,
    };
  },
  async mounted() {
    // await this.getwharhouseId();
    //await this.getUser();
    if (this.$f.QueryStringToobject())
      this.filters = this.$f.QueryStringToobject();
    else this.$f.objectToQueryString({ limit: this.limit });

    if (this.filters.name) await this.search(this.filters.name);
    else this.setProducts(this.filters);

    //await this.getCategories();
    if (this.currentUser.type == "Seller") {
      this.setDataSelect("seller", this.sellers, "yes");
      let pos = this.configList.columns
        .map(function (e) {
          return e.name;
        })
        .indexOf("Seller");
      if (pos != -1) this.configList.columns.splice(pos, 1);
    } else {
      await this.getSellers();
      this.setDataSelect("seller", this.sellers, null);
    }

    //this.filters[0].values = this.categories;
    this.setDataSelect("category", this.categories, null);
  },

  filters: {},
  computed: {
    ...mapState({
      selectedDefaultWarehouse: (state) => state.app.defaultWarehouse,
      features: (state) => state.features.all,
    }),
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: lodash.pick(this.currentUser, ["countries"]),
      });
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) {
      //await this.getwharhouseId();
    },
    idWharhouse: async function (oldVal, newVal) {
      this.idWharhouse = oldVal;
      await this.reset();
      if (this.currentUser.type != "Seller") {
        await this.getSellers();
        this.setDataSelect("seller", this.sellers, null);
      }
    },
  },
  methods: {
    async getwharhouseId() {
      await this.$store
        .dispatch("wharhouse/getwharhouseId", {
          country: await this.warhouseSelected,
        })
        .then((res) => {
          this.idWharhouse = res;
        });
    },
    async getRowDetail(item, id) {
      // let rows = this.selectedRows;
      let columns = document.querySelectorAll(".rowCheckbox");
      let element = document.querySelector(".rowCheckboxProduct-" + id);

      if (this.tabId.length > 0) {
        this.tabId = [];
        columns.forEach((column) => {
          column.checked = false;
        });
      }
      element.checked = true;

      let itemInRow = this.tabId.filter((item) => item === id);
      let isItemInRow = itemInRow.length > 0;

      if (isItemInRow) {
        let pos = this.tabId
          .map(function (e) {
            return e;
          })
          .indexOf(id);
        this.tabId.splice(pos, 1);
      } else {
        this.tabId.push(id);
        const search = { _id: id };
        const res = await this.$server.find("products", search);
        if (res.content.results && this.idWharhouse) {
          this.selectProduct = res.content.results[0];
          this.producWarhouse = this.getElementInArray(
            this.selectProduct.details,
            this.idWharhouse._id
          );
        }
      }
    },
    getElementInArray(data, value) {
      var result = data.filter(function (o) {
        return o.warehouse._id == value.toString();
      });
      return result ? result[0] : null; // or undefined
    },
    emtyTabId() {
      let columns = document.querySelectorAll(".rowCheckbox");
      columns.forEach((column) => {
        column.checked = false;
      });
      this.tabId = [];
    },
    setDataSelect(model, data, splice) {
      for (var i = 0; i < this.configList.filters.elements.champs.length; i++) {
        if (
          this.configList.filters.elements.champs[i].type === "select" &&
          this.configList.filters.elements.champs[i].key === model
        ) {
          if (!splice) {
            this.configList.filters.elements.champs[i].values = data;
          } else {
            this.configList.filters.elements.champs.splice(i, 1);
          }
        }
      }
    },
    setUserId(filters) {
      return (filters.seller = this.currentUser._id);
    },
    async getProducts(filters) {
      if (this.currentUser && this.idWharhouse) {
        //filters=this.setUserId();
        if (this.currentUser.type == "Seller") {
          this.filters.seller = this.currentUser._id;
        }
        this.filters["details.warehouse"] =
          this.selectedDefaultWarehouse &&
          !this.features["multi-warehouses"].isActive
            ? this.selectedDefaultWarehouse
            : this.idWharhouse._id;

        const res = await this.$server.search("products", this.filters);
        if (res.content.results) {
          this.products = res.content.results;
          this.paginate.total = res.content.total;
          this.paginate.lastpage = res.content.last_page;
          this.paginate.per_page = res.content.per_page;
          this.paginate.currentpage = res.content.current_page;
        } else {
          this.products = [];
          this.paginate = {
            total: 0,
            currentpage: 1,
            lastpage: 1,
            per_page: 0,
          };
        }
      }
    },

    async getWharhouseContryById(id) {
      const res = await this.$server.get("warehouses", { _id: id });
      return this.$f.getCountryCode(res.content.country);
    },
    async setProducts(filter) {
      this.loading = true;
      await this.getProducts(filter);
      this.loading = false;
      let products = this.products;
      for (let i in products) {
        let qty = products[i].quantity;
        if (products[i] && products[i].quantity && qty && qty.inStock)
          products[i].inStock = qty.inStock;
        if (products[i] && products[i].quantity && qty && qty.total)
          products[i].total = qty.total;
        if (products[i] && products[i].quantity && qty && qty.defective)
          products[i].defective = qty.defective;
        if (products[i] && products[i].category)
          products[i].category = products[i].category.name;
        if (products[i] && products[i].seller)
          products[i].seller = products[i].seller.fullName;
        if (products[i].details) {
          for (let j in products[i].details) {
            if (products[i].details[j] && products[i].details[j].warehouse)
              products[i].details[j].country = this.$f.getCountryCode(
                products[i].details[j].warehouse.country
              );
          }
        }
      }
      this.content = products;
    },

    async refresh() {
      if (this.$f.QueryStringToobject())
        this.filters = this.$f.QueryStringToobject();
      this.$f.objectToQueryString(this.filters);
      if (this.filters.name) await this.search(this.filters.name);
      else await this.setProducts(this.filters);
      if (
        this.filters["page"] &&
        this.filters["page"] > 1 &&
        this.content.length == 0
      ) {
        this.filters["page"] = --this.filters["page"];
        this.$f.objectToQueryString(this.filters);
        await this.setProducts(this.filters);
      }
    },

    /*********************************************************************************/
    async reset(data) {
      let lmitt = this.limit;
      if (this.$f.QueryStringToobject())
        this.filters = this.$f.QueryStringToobject();
      if (this.filters["limit"]) lmitt = this.filters["limit"];
      this.filters["page"] = 1;
      this.$f.objectToQueryString({ limit: lmitt });

      await this.setProducts(this.filters);
    },
    async search(data) {
      this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      let limitt = this.limit;
      if (this.filters["limit"]) limitt = this.filters["limit"];

      const search = { name: data.trim(), limit: parseInt(limitt) };
      if (this.currentUser.type == "Seller") {
        search.seller = this.currentUser._id;
      }

      search["details.warehouse"] =
        this.selectedDefaultWarehouse &&
        !this.features["multi-warehouses"].isActive
          ? this.selectedDefaultWarehouse
          : this.idWharhouse._id;

      this.$f.objectToQueryString(search);
      let resproduct = await this.$server.find("products", search);

      let resultProduct = [];
      if (resproduct.content && resproduct.content != "no products found") {
        resultProduct = resproduct.content.results;
        for (let k in resultProduct) {
          const qty = resultProduct[k].quantity;
          resultProduct[k].inStock = qty.inStock;
          resultProduct[k].total = qty.total;
          resultProduct[k].defective = qty.defective;
          if (resultProduct[k].seller)
            resultProduct[k].seller = resultProduct[k].seller.fullName;
          for (let j in resultProduct[k].details) {
            resultProduct[k].details[j].country = this.$f.getCountryCode(
              resultProduct[k].details[j].warehouse.country
            );
          }
        }
        this.content = resultProduct;
      } else this.content = [];
    },
    async filtrer(data) {
      if (this.$f.QueryStringToobject())
        this.filters = this.$f.QueryStringToobject();
      delete this.filters.page;
      delete this.filters.name;

      if (!this.filters["limit"]) this.filters["limit"] = this.limit;
      this.paginate.currentpage = 1;

      if (data.quantity) this.filters.quantity = data.quantity;
      else delete this.filters.quantity;
      if (data.price) this.filters.price = data.price;
      else delete this.filters.price;
      if (data.date) this.filters.date = data.date;
      else delete this.filters.date;

      if (data.seller) this.filters.seller = data.seller._id;
      else delete this.filters.seller;
      this.$f.objectToQueryString(this.filters);
      await this.setProducts(this.filters);
    },
    /****************************************************************************/
    async getCategories() {
      const res = await this.$server.search("categories");
      if (res.content.results) this.categories = res.content.results;
      else this.categories = [];
    },
    async getSellers() {
      const filter = {
        type: "Seller",
        countries: await this.warhouseSelected,
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
    },
    searchSellers: lodash.debounce(async function (search, event) {
      const filter = {
        type: "Seller",
        limit: this.limit,
        countries: await this.warhouseSelected,
        name: search,
      };
      const res = await this.$server.find("users", filter);

      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
      this.setDataSelect("seller", this.sellers, null);
    }, 500),
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.currentUser = res.content;
      else this.currentUser = [];
    },
    /***************************************************************************************/
    async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }

      if (this.$f.QueryStringToobject())
        this.filters = this.$f.QueryStringToobject();
      this.filters["page"] = page;
      this.$f.objectToQueryString(this.filters);

      this.setProducts(this.filters);
    },
  },
};
</script>

<style></style>
